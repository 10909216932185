<template>
	<div>
		<v-card>
			<v-card-title
				class="indigo white--text"
			>
				<h3 v-text="data.descricao" ></h3>
			</v-card-title>
			<v-card-subtitle 
				class="indigo white--text"
			>
				<h3 v-text="'R$ ' + data.valor"  ></h3>
			</v-card-subtitle>

			<v-card-text class="pt-4" >
				<h4 v-text="'Grupo: ' + data.descricaoGrupo" ></h4>
				<h5 v-text="'Sub Grupo: ' + (data.descricaoSubGrupo === null ? 'Sem sub grupo' : data.descricaoSubGrupo)" ></h5>
				<h5 v-text="'Unidade: ' + data.unidade" ></h5>

				<v-divider
					class="my-4"
				></v-divider>

				<p v-text="'Descrição: ' + (data.observacoes === null ? '' : data.observacoes)" ></p>

				<template v-if="adicionais.length > 0" >
					<v-divider
						class="my-4"
					></v-divider>

					<h3>Adicionais</h3>

					<v-list
						flat
						three-line
					>
						<v-list-item v-for="(item, id) in adicionais" :key="id" >
							<template>
								<v-list-item-action>
									<v-checkbox
										:value="true"
										v-model="item.selected"
									></v-checkbox>
								</v-list-item-action>

								<v-list-item-content>
									<v-list-item-title>{{item.descricao}}</v-list-item-title>
									<v-list-item-subtitle>R$ {{item.valor}}</v-list-item-subtitle>
								</v-list-item-content>
							</template>
						</v-list-item>
					</v-list>
				</template>
			</v-card-text>

			<v-card-actions>
				<v-row>
					<v-col
						cols="4"
					>
						<v-text-field
							label="Quantidade"
							prepend-icon="mdi-minus"
							append-outer-icon="mdi-plus"
							v-model="quantidade"
							@click:append-outer="aumentarQuantidade()"
							@click:prepend="diminuirQuantidade()"
						></v-text-field>
					</v-col>
					<v-divider
						class="my-4"
						vertical
					></v-divider>
					<v-col
						cols="8"
						class="py-8"
					>
						<v-btn
							color="indigo"
							dark
							block
							@click.prevent="adicionar()"
						>
							Adicionar ao carrinho
						</v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
		<v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
	</div>
</template>
<script>
	export default {
		name: 'vwProduto',
		data(){
			return {
				overlay: false,
				data: {},
				adicionais: [],
				quantidade: 1
			}
		},
		computed: {
			id(){
				return this.$route.params.id
			},

			selected(){
				return this.adicionais.filter(item => {
					if (item.selected === true) {
						return item
					}
				})
			}
		},
		methods: {
			buscarProduto: function(){
				this.overlay = true
				this.$store.dispatch('CARDAPIO', this.id)
					.then(response => ( response.json() ) )
					.then(resJSON => {
						this.data = resJSON.response[0]

						// this.produto.produto 	= this.data.id
						// this.produto.descricao 	= this.data.descricao
						// this.produto.valor 		= this.data.valor
					})
					.catch(error => {
						console.error('Error', '=>', error)
					})
					.finally(() => {
						this.$store.dispatch('ADICIONAIS', this.data.idGrupo)
							.then(response => (response.json()))
							.then(resJSON => {
								this.adicionais = resJSON.response
							})
							.catch(error => {
								console.error('Error', '=>', error)
							})
							.finally(() => {
								this.overlay = false
							})
					})
			},

			diminuirQuantidade: function(){
				if(this.quantidade <= 1 || isNaN(this.quantidade)){
					this.quantidade = 1
				}else{
					this.quantidade --
				}
			},

			aumentarQuantidade: function(){
				this.quantidade ++
			},

			adicionar: function(){
				let valor = this.data.valor

				this.selected.forEach(element => {
					valor = valor + element.valor
				});

				valor = valor * this.quantidade

				let produto = {
					produto: this.data.id,
					quantidade: this.quantidade,
					descricao: this.data.descricao,
					valor: this.data.valor.toString(),
					valor_total: valor.toString(),
					adicionais: this.selected
				}

				this.$store.dispatch('ADICIONAR_ITEM', produto)
			}
		},
		mounted(){
			this.buscarProduto()
		}
	}
</script>